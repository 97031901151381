<template>
  <div class="communication">
    <el-button
      class="back-button"
      size="small"
      @click="hasHistory()
        ? $router.go(-1)
        : $router.push('/')"
      type="primary">
      {{ $t('global.back') }}
    </el-button>
    <div
      class="communication__wrapper"
      v-loading="isLoading"
      v-if="item">
      <h1
        class="title communication__title"
        v-html="item.title.rendered">
      </h1>
      <div
        class="communication__content"
        v-html="item.content.rendered">
      </div>
    </div>
  </div>
</template>

<script>
import { investmentAdvisoryApi } from '@/api';

export default {
  name: 'MarketTrend',
  props: ['id'],
  data() {
    return {
      item: this.$route.params.item || null,
      isLoading: false,
    };
  },
  async created() {
    if (!this.item) {
      this.getRemoteItem();
    }
  },
  methods: {
    async getRemoteItem() {
      try {
        this.isLoading = true;
        const response = await investmentAdvisoryApi.getMarketTrend(this.id);
        this.isLoading = false;
        this.item = response.data;
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
